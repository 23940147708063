<script setup lang="ts">
import {onMounted, watch} from 'vue';
import {useAuthStore} from "@/stores/auth";
import {useCartStore} from '@/stores/cart';
import svgShoppingCart from "~/assets/icons/shopping-cart.svg?raw";
import svgUserAlt from "~/assets/icons/user-alt.svg?raw";

const isOpen = ref(false)
const isUserOpen = ref(false)
const cartStore = useCartStore()
const totalItemsInCart = computed(() => cartStore.totalItems)

onMounted(() => {
  cartStore.hydrateFromLocalStorage()
});

function toggleIsOpen() {
  isOpen.value = !isOpen.value
}

function toggleIsUserOpen() {
  isUserOpen.value = !isUserOpen.value
}

useHead({
  htmlAttrs: {
    class: 'has-navbar-fixed-top'
  }
})

const {isLoggedIn} = useAuthStore()

onMounted(() => {
  document.addEventListener('click', handleClickOutside)

  watch(totalItemsInCart, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      bounceItemCount();
    }
  })
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})

import {ref} from 'vue'

const itemCount = ref(null)
const itemCount2 = ref(null)

function setItemCountRef(el) {
  itemCount.value = el
}

function setItemCountRef2(el) {
  itemCount2.value = el
}

function bounceItemCount() {
  if (itemCount.value) {
    nextTick(() => {
      itemCount.value?.classList?.add('bounce')
      itemCount2.value?.classList?.add('bounce')
    });
    setTimeout(() => {
      itemCount.value?.classList?.remove('bounce')
      itemCount2.value?.classList?.remove('bounce')
    }, 500);
  }
}

const menu = ref(null);
const handleClickOutside = (event) => {
  if (menu.value && !menu.value.contains(event.target) && isOpen.value) {
    isOpen.value = false
  }
}
</script>

<template>
  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="nav-inner">
      <div class="navbar-brand">
        <a @click.stop="toggleIsOpen"
           role="button"
           class="navbar-burger"
           :class="isOpen ? 'is-active' : ''"
           aria-label="menu"
           aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
        <a class="navbar-item plain logo" href="/">
          <nuxt-img
              src="/images/logo.jpg"
              alt="Dreaming Unicorns Logo"
              sizes="xs:100"
          />
        </a>

        <div class="navbar-item shopping-cart-container mobile-only">
          <NuxtLink to="/cart">
            <div class="shopping-cart" v-html="svgShoppingCart"/>
            <div class="badge" v-show="totalItemsInCart" :ref="setItemCountRef2">{{ totalItemsInCart }}</div>
          </NuxtLink>
        </div>
      </div>

      <div ref="menu"
           class="navbar-menu"
           :class="{ 'is-active': isOpen, 'is-not-active': !isOpen }"
           @click.stop="isOpen = false"
      >
        <div class="navbar-start">
          <NuxtLink class="navbar-item" to="/">Home</NuxtLink>
          <!--<NuxtLink class="navbar-item" to="/shop">Design</NuxtLink>-->
          <NuxtLink class="navbar-item" to="/shop">Shop</NuxtLink>
          <NuxtLink class="navbar-item" to="/guide">Guide</NuxtLink>
          <NuxtLink class="navbar-item" to="/about">About</NuxtLink>
          <NuxtLink class="navbar-item" to="/contact">Contact</NuxtLink>
        </div>
        <div class="navbar-end">
          <div class="navbar-item is-hidden-touch"
               :class="isUserOpen ? 'is-active' : ''"
               @mouseover="isUserOpen = true"
               @mouseleave="isUserOpen = false"
          >
            <a @click="toggleIsUserOpen"
               class="is-arrowless">
              <div v-html="svgUserAlt"/>
            </a>

            <div class="navbar-dropdown is-boxed">
              <a class="navbar-item">
                <NuxtLink to="/dashboard">My Account</NuxtLink>
              </a>
              <hr class="navbar-divider">
              <a class="navbar-item">
                <NuxtLink to="/logout">Log Out</NuxtLink>
              </a>
            </div>
          </div>

          <hr class="navbar-divider mobile-only">

          <div class="navbar-item shopping-cart-container">
            <NuxtLink to="/cart">
              <span class="mobile-only">Your Shopping Cart <small>({{ totalItemsInCart }})</small></span>
              <div class="is-hidden-touch shopping-cart" v-html="svgShoppingCart"/>
              <div class="is-hidden-touch badge" v-show="totalItemsInCart" :ref="setItemCountRef">{{
                  totalItemsInCart
                }}
              </div>
            </NuxtLink>
          </div>

          <hr class="navbar-divider mobile-only">

          <div class="navbar-item mobile-only">
            <NuxtLink to="/dashboard">My Account</NuxtLink>
          </div>
          <div class="navbar-item mobile-only">
            <NuxtLink to="/logout">Log Out</NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss">
@import "scss/variables";
@import "bulma/sass/utilities/mixins";

@keyframes bounce {
  0% {
    transform: scale(1);
    color: #fff;
  }
  20% {
    transform: scale(1.5);
    color: #fff;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.bounce {
  animation: bounce 1s;
}

.nav-inner {
  display: flex;
  width: 100%;

  @include touch {
    display: unset;
  }

  @include fullhd {
    max-width: 1344px;
  }
}

html.has-navbar-fixed-top {
  padding-top: 5.5rem !important;
}

.navbar {
  border-top: 4px $pink solid;
  border-bottom: 1px dotted $blue;
  box-shadow: $blue 0 -10px 35px;
  min-height: 5.25rem;
  display: flex;
  justify-content: center;
  max-width: 100vw;

  @include desktop {
    .mobile-only {
      display: none;
    }
  }

  .navbar-divider {
    &.mobile-only {
      display: block;
      width: 100%;
      margin: auto;
    }
  }

  .navbar-brand {
    min-height: 5.25rem;
    justify-content: space-between;
    margin-right: 1rem;

    @include tablet {
      margin-left: 0.5rem;
    }

    @include touch {
      width: 100%;
    }

    @include fullhd {
      margin-right: 1rem;
      margin-left: 0;
    }

    .logo {
      @include fullhd {
        padding-left: 0;
      }

      img {
        max-height: 4rem;
      }
    }

    img {
      transition: scale 400ms;

      &:hover {
        scale: 1.2;
      }
    }

    .navbar-burger {
      min-height: 5.25rem;
      width: 5.25rem;
      margin-left: revert;

      &:hover {
        background-color: #fff;

        @include desktop {
          background-color: findLightColor($pink, 100%);
          color: $pink;
        }
      }

      &.is-active {
        span {
          width: 30px;
          margin-left: -3px;
        }

        span:nth-child(1) {
          transform: translateY(10px) rotate(45deg);
        }

        span:nth-child(3) {
          transform: translateY(-6px) rotate(-45deg);
        }
      }

      span {
        transition-duration: 150ms;
        height: 2px;
        width: 24px;
        left: calc(50% - 12px);

        &:nth-child(1) {
          top: calc(50% - 9px);
        }

        &:nth-child(2) {
          top: calc(50% - 1px);
        }

        &:nth-child(3) {
          top: calc(50% + 7px);
        }
      }
    }
  }

  .navbar-start {
    .navbar-item {
      padding: 0 1rem;
      margin-top: 15px;
      margin-bottom: 15px;

      &:focus-within {
        color: $grey;
        background: $white;
      }
    }
  }

  @include touch {
    &.is-fixed-top {
      .navbar-menu {
        padding-bottom: 35px;

        .navbar-start {
          .navbar-item {
            padding: 1.25rem 1.5rem;
            text-align: center;
          }
        }

        .navbar-end {
          .navbar-item {
            .buttons {
              flex-direction: column;
              align-items: flex-start;

              .button {
                margin-top: 10px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }

  .navbar-dropdown {
    padding-left: 5px;
    padding-right: 5px;

    a.navbar-item {
      padding: 0.375rem 1rem;

      a {
        padding: 0;
        line-height: 1.5;
      }
    }
  }

  .navbar-item {
    padding: 0;
    transition: background-color ease-out 200ms;
    font-weight: 500;
    color: $grey;
    cursor: pointer;
    font-size: 17px;
    border-radius: 10px;
    margin-top: 4px;
    margin-bottom: 4px;

    a {
      color: $grey;
      font-size: 17px;
      padding: 0.5rem 1.5rem;
      padding-bottom: 0;
      line-height: 68px;

      @include touch {
        text-align: center;
        width: 100%;
        display: block;
        padding: 1.25rem 1.5rem;
        line-height: 1.5;
      }
    }

    svg {
      width: 27px;
    }

    &.shopping-cart-container {
      a {
        padding-right: 2rem;
      }

      @include touch {
        margin-right: 20px;

        a {
          padding: 1rem 2rem 1rem 1.75rem;
        }
      }

      @include mobile {
        margin-right: 0;
      }

      @include fullhd {
        margin-right: -1rem;
      }

      .shopping-cart {
        @include touch {
          margin-top: 10px;
        }

        svg {
          width: 30px;
        }
      }

      .badge {
        border-radius: 100%;
        font-size: 9px;
        height: 22px;
        width: 22px;
        position: absolute;
        top: 13px;
        left: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $pinkVeryLight;
        color: #444444;
        transition: all 200ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
        transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
      }

      @include desktop {
        &:hover {
          .badge {
            background-color: $pink;
            color: $white;
            font-size: 11px;
            scale: 1.2;
          }
        }
      }
    }


    &:not(.plain) {
      @include desktop {
        &:hover {
          background-color: findLightColor($pink, 100%);
          color: $pink;

          a {
            color: $pink;
          }

          svg {
            fill: $pink;
          }

          .navbar-item {
            color: $grey;

            a {
              color: $grey;
            }

            &:hover {
              color: $pink;

              a {
                color: $pink;
              }


            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "bulma/sass/utilities/mixins";

.navbar.is-fixed-top {
  .navbar-menu {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0; /* for vertical scrollbars */
      height: 0; /* for horizontal scrollbars */
    }

    &.is-active {
      animation: navAnimation 0.7s ease forwards;
    }
  }
}

@keyframes navAnimation {
  0% {
    opacity: 0;
    height: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 673px;
  }
}
</style>