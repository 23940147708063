<script>
import { useNotificationsStore } from "@/stores/notifications";

export default {
    setup() {
        const notificationsStore = useNotificationsStore();

        function notificationClasses(type) {
            switch (type) {
                case "success":
                    return "is-success";
                case "error":
                    return "is-danger";
                case "info":
                    return "is-info";
                default:
                    return "";
            }
        }

        function removeNotification(notification) {
            notificationsStore.removeNotification(notification);
        }

        function progress(notification) {
            if (notification.autoDismiss) {
                const elapsed = Date.now() - notification.timestamp;
                return Math.min(100, (elapsed / 10000) * 100);
            } else {
                return 0;
            }
        }

        return {
            allNotifications: notificationsStore.notifications,
            notificationClasses,
            removeNotification,
            progress,
        };
    },
};
</script>

<template>
    <div class="notifications-container">
        <div
            v-for="(notification, index) in allNotifications"
            :key="index"
            :class="['notification', notificationClasses(notification.type)]"
            @click="removeNotification(notification)"
        >
            <button
                class="delete"
                @click="removeNotification(notification)"
            ></button>
            {{ notification.message }}
            <div v-if="notification.autoDismiss" class="progress-bar"></div>
        </div>
    </div>
</template>

<style lang="scss">
.notifications-container {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    cursor: pointer;

    .notification {
        position: relative;
        padding-right: 30px;

        .progress-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #b4b2b2;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 0%;
                height: 100%;
                background-color: #595656;
                animation: progress-bar 15s linear forwards;
            }

            &.is-success {
                background-color: #23d160;
            }

            &.is-danger {
                background-color: #ff3860;
            }

            &.is-info {
                background-color: #209cee;
            }
        }
    }
}

@keyframes progress-bar {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

</style>
