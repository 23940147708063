<script setup lang="ts">
import {computed} from 'vue';
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import LoadingOverlay from "~/components/generic/LoadingOverlay.vue";
import NotificationAlerts from "@/components/NotificationAlerts.vue";

let loading = ref(false)
const nuxtApp = useNuxtApp()

nuxtApp.hook('page:start', () => {
  loading.value = true
})

nuxtApp.hook('page:finish', () => {
  setTimeout(() => loading.value = false, 50)
})

const animationDuration = computed(() => {
  return Math.floor(Math.random() * (80 - 40 + 1) + 40) + 's';
});

const { $pwa } = useNuxtApp()

onMounted(() => {
  if ($pwa.offlineReady) {
    //console.log('App ready to work offline')
  }
})

</script>

<template>
  <div id="app-layout">
    <VitePwaManifest />

    <header>
      <div class="header">
        <Nav/>
      </div>
    </header>

    <main class="has-loading-overlay" :style="{ animationDuration: animationDuration}">
      <div class="outer-container can-overlay">
        <NuxtLoadingIndicator/>

        <NotificationAlerts/>

        <slot/>
      </div>
      <LoadingOverlay :loading="loading"/>
    </main>

    <Footer/>
  </div>
</template>

<style lang="scss">
@import "scss/variables";
@import "bulma/bulma";
@import "bulma/sass/utilities/mixins";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;700&display=swap');

.page-enter-active, .page-leave-active {
  transition: all 0.2s;
}

.page-enter-from, .page-leave-to {
  opacity: 0;
  filter: blur(0.5rem);
}

html {
  background-color: $blue;
}

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

div.content {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }
}

.header {

}

.input,
.textarea,
.autocomplete .control .tags {
  background: #f9f9f9;
}

.panel-heading {
  cursor: default;
}

#app-layout {
  background-color: $grey-lightest;
}

@keyframes breathe {
  0%, 100% {
    background-size: 100%;
  }
  30% {
    background-size: 140%;
  }
  60% {
    background-size: 180%;
  }
  90% {
    background-size: 130%;
  }
}

main {
  background-image: url("/images/bg-img.jpg");
  background-size: cover;
  background-position: center;
  //animation-name: breathe;
  //animation-timing-function: ease-in-out;
  //animation-iteration-count: infinite;
}

.outer-container {
  background-color: rgba(220, 138, 193, 0.07);
  backdrop-filter: blur(60px);
  min-height: 200px;
  padding-bottom: 30px;

  @include touch {
    backdrop-filter: blur(20px);
  }
}

.title {
  cursor: default;
}

h1.title {
  font-weight: 400;
}

h2.title {
  font-weight: 400;
  font-size: 24px;
}

.content {
  p {
    margin-bottom: 1.5em;
    line-height: 32px;
    font-size: 17px;
  }
}

.field {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.select select:focus,
.textarea:focus,
.input:focus,
.select select.is-focused,
.is-focused.textarea,
.is-focused.input,
.select select:active,
.textarea:active,
.input:active,
.select select.is-active,
.is-active.textarea,
.is-active.input {
  border-color: $pink;
  box-shadow: 0 0 0 0.3em findLightColor($pink, 90%);
}

.grecaptcha-badge {
  visibility: hidden;
}

.has-loading-overlay {
  display: grid;
}

.can-overlay, .loading-overlay {
  grid-area: 1 / 1;
}

.loading-overlay {
  z-index: 25;
  background-color: rgba($blue, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  border: 2px solid rgba($blue, 0.1);
  box-shadow: inset 0 0 150px 60px rgba(255, 255, 255, 0.4);
  margin: -20px;
  padding: 50px;

  &.dark {
    background-color: lighten($blue, 11%);
  }
}

main {
  &.has-loading-overlay {
    > .loading-overlay {
      .lds-heart {
        top: 45vh;
        position: fixed;
      }

      .text {
        top: calc(45vh + 80px);
        position: fixed;
      }
    }
  }
}

a.revert {
  color: $black;

  &:hover {
    color: $pink;
  }
}

.tag {
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: default;
}

</style>